<div class="ui fixed top menu po-header borderless" [class.po-big]="smallMenu">
  <div class="po-xs-visible po-mobile-toggle">
    <a class="item" (click)="toggleMobileMenu()">
      <i class="icon bars" [class.active]="mobileMenu"></i>
    </a>
  </div>
  <div class="po-xs-visible">
    <div class="item header">
      <img src="assets/img/logo.png">
    </div>
  </div>
  <div class="po-xs-hidden po-menu-toggle">
    <a class="item" (click)="toggleMenu()">
      <i class="icon caret" [class.left]="!smallMenu" [class.right]="smallMenu"></i>
    </a>
  </div>
  <div class="ui po-xs-hidden dropdown item" [defaultText]="warehouse ? warehouse.name : 'Seleccione un almacén'" my-dropdown
  *ngIf="session.accessLevel >= 10 && warehouses && warehouses.length > 0">
    <div class="item" *ngFor="let w of warehouses" [class.active]="warehouse && w.id ==warehouse.id" (click)="changeWarehouse(w)">{{ w.name }}</div>
  </div>
  <div class="right menu">
    <a class="item ui dropdown" [defaultImage]="'assets/img/users/' + (session.image ? session.image : 'blank.png')"
    [defaultText]="session.name" my-dropdown>
      <div class="item" [routerLink]="['/account']">
        <i class="icon user"></i> Mis datos
      </div>
      <ng-container *ngIf="session.accessLevel >= 9">
        <div class="divider"></div>
        <a class="item" [class.active]="branch && branch.id == b.id" *ngFor="let b of branchs" (click)="changeBranch(b)">
          <i class="icon building"></i>
          {{ b.name }}
        </a>
      </ng-container>
      <div class="divider"></div>
      <div class="item" (click)="logout()">
        <i class="icon sign-out"></i> Cerrar sesión
      </div>
    </a>
  </div>
</div>
<div [className]="'ui inverted vertical left fixed menu po-menu'
+ (mobileMenu ? ' po-mobile-open' : (mobileMenu === false ? ' po-hidden-text' : ''))
+ (smallMenu ? ' po-small' : (smallMenu === false ? ' po-hidden-text' : ''))">
  <div class="item header">
    <img src="assets/img/logo.png">
  </div>
  <a class="item" [routerLink]="['/']" [class.active]="sector == ''">
    <i class="icon dashboard"></i>
    <span>Dashboard</span>
  </a>
  <a class="item" [routerLink]="['/parts']" [class.active]="sector == 'parts'" *ngIf="isSectorAvailable('parts')">
    <i class="icon box"></i>
    <span>Almacén</span>
  </a>
  <a class="item" [routerLink]="['/reports']" [class.active]="sector == 'reports'" *ngIf="isSectorAvailable('reports')">
    <i class="icon clipboard list"></i>
    <span>Reportes</span>
  </a>
  <a class="item" [routerLink]="['/settings']" [class.active]="sector == 'settings'" *ngIf="isSectorAvailable('settings')">
    <i class="icon settings"></i>
    <span>Configuración</span>
  </a>
  <div class="po-xs-visible" *ngIf="session.accessLevel >= 10 && warehouses && warehouses.length > 0">
    <div class="divider"></div>
    <div class="item">
      <span>Almacenes</span>
    </div>
    <div class="item" *ngFor="let w of warehouses" [class.active]="warehouse && w.id == warehouse.id" (click)="changeWarehouse(w)">
      <span>{{ w.name }}</span>
    </div>
  </div>


</div>
<div [className]="'po-content ' + (smallMenu ? 'po-big ' : '') + (mobileMenu ? 'po-mobile-open ' : '')">
  <div class="progress-line" *ngIf="urlLoading"></div>
  <div class="po-xs-visible">
    <div [className]="'ui dimmer po-mobile-dimmer '
    + (mobileMenu === true
      ? 'transition visible active animating in fade'
      : (mobileMenu === false ? 'transition visible animating out fade' : ''))"
      (click)="toggleMobileMenu()"
    >
    </div>
  </div>
  <router-outlet></router-outlet>
</div>
<div class="po-footer" [class.po-big]="smallMenu" [class.po-mobile-open]="mobileMenu">
  TITSA | Versión <strong>1.0</strong>
</div>
